<template>
  <v-row>
    <!-- form -->
    <v-col
      cols="8"
      md="4"
    >
      <v-alert
        v-show="created"
        type="success"
      >
        Enregistrement réussi!
      </v-alert>
      <v-alert
        v-show="deleted"
        type="success"
      >
        Suppression réussi!
      </v-alert>
      <v-form>
        <v-text-field
          v-model="recurringTask.name"
          label="Nom"
          outlined
          dense
          placeholder="Repassage du linge"
        ></v-text-field>

        <v-textarea
          v-model="recurringTask.description"
          label="Description"
          outlined
          dense
          placeholder="Les vêtements de Marie et de Clément"
        ></v-textarea>

        <v-text-field
          v-model="recurringTask.date"
          label="Date de la tâche"
          type="date"
          outlined
          dense
        ></v-text-field>

        <v-btn
          color="primary"
          @click="handleNewRecurringTask"
        >
          Enregistrer
        </v-btn>
        <!--<v-btn
          type="reset"
          outlined
          class="mx-2"
        >
          Reset
        </v-btn>-->
      </v-form>
    </v-col>
    <!-- form -->

    <!-- fixed header -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Les tâches récurrentes</v-card-title>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            hide-details
          >
          </v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="getAllRecurringTask"
          :search="search"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.date }}</td>
              <td>
                <v-btn
                  class="text-center"
                  fab
                  small
                  @click="deleteRecurringTask(item.id)"
                >
                  <v-icon size="30">
                    {{ icons.mdiCloseOutline }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {
  mdiAccountOutline, mdiEmailOutline, mdiCellphone, mdiLockOutline, mdiCloseOutline,
} from '@mdi/js'
import RecurringTask from '@/models/bullets/recurringTask'

export default {

  data() {
    return {
      recurringTask: new RecurringTask(),
      recurringTasks: [],
      search: '',
      headers: [
        { text: 'TITRE', value: 'name' },
        { text: 'DESCRIPTION', value: 'description' },
        { text: 'DATE', value: 'date' },
        { text: 'ACTIONS' },
      ],
    }
  },
  computed: {
    created() {
      return this.$store.state.recurringtask.status.created
    },
    deleted() {
      return this.$store.state.recurringtask.status.deleted
    },
    getAllRecurringTask() {
      return this.$store.state.recurringtask.recurringtasks
    },
  },
  created() {
    this.loadAllRecurringTask()
  },
  setup() {
    return {
      // icons
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
        mdiCloseOutline,
      },
    }
  },
  methods: {
    handleNewRecurringTask() {
      if (this.recurringTask.name && this.recurringTask.description && this.recurringTask.date) {
        this.$store.dispatch('recurringtask/new', this.recurringTask).then(
          () => {
            this.recurringTask = new RecurringTask('', '', '')
          },
        )
      }
    },
    loadAllRecurringTask() {
      this.$store.dispatch('recurringtask/getAll')
    },
    deleteRecurringTask(idTodelete) {
      this.$store.dispatch('recurringtask/delete', idTodelete)
    },
  },
}
</script>
